/* eslint-disable require-jsdoc */
export default function OnSelectionChangePlugin(editor, onSelectionChange) {
	if (onSelectionChange) {
		return editor.registerUpdateListener(
			({
				editorState,
				dirtyElements,
				dirtyLeaves,
				normalizedNodes,
				prevEditorState,
				tags
			}) => {
				onSelectionChange(editorState, editor, {
					dirtyElements,
					dirtyLeaves,
					normalizedNodes,
					editorState,
					prevEditorState,
					tags
				});
			}
		);
	}

	return null;
}
