/**
 * Applies a node replacement in the editor.
 *
 * @param {Object} node - The node to be replaced.
 * @param {Object} editor - The editor instance.
 * @return {Object} The replacement node or the original node if replacement fails.
 * @throws Will log an error if the node type is not registered to the editor.
 * @throws Will log an error if the replacement node is not an instance of the expected class.
 * @throws Will log an error if the replacement node is not a subclass of the original node.
 * @throws Will log an error if the replacement node reuses the key of the original node.
 */
export function $applyNodeReplacement(node, editor) {
	const nodeType = node.constructor.getType();
	const registeredNode = editor._nodes.get(nodeType);
	if (registeredNode == undefined)
		console.error(
			`$applyNodeReplacement node ${node.constructor.name} with type ${nodeType} must be registered to the editor. You can do this by passing the node class via the "nodes" array in the editor config.`
		);
	const { replace, replaceWithKlass } = registeredNode;
	if (replace !== null) {
		const replacementNode = replace(node);
		const replacementNodeKlass = replacementNode.constructor;
		if (
			replaceWithKlass !== null &&
			!(replacementNode instanceof replaceWithKlass)
		) {
			console.error(
				`$applyNodeReplacement failed. Expected replacement node to be an instance of ${
					replaceWithKlass.name
				} with type ${replaceWithKlass.getType()} but returned ${
					replacementNodeKlass.name
				} with type ${replacementNodeKlass.getType()} from original node ${
					node.constructor.name
				} with type ${nodeType}`
			);
		} else if (
			!(
				replacementNode instanceof node.constructor &&
				replacementNodeKlass !== node.constructor
			)
		) {
			console.error(
				`$applyNodeReplacement failed. Ensure replacement node ${
					replacementNodeKlass.name
				} with type ${replacementNodeKlass.getType()} is a subclass of the original node ${
					node.constructor.name
				} with type ${nodeType}.`
			);
		}
		if (replacementNode.__key === node.__key) {
			console.error(
				`$applyNodeReplacement failed. Ensure that the key argument is *not* used in your replace function (from node ${
					node.constructor.name
				} with type ${nodeType} to node ${
					replacementNodeKlass.name
				} with type ${replacementNodeKlass.getType()}), Node keys must never be re-used except by the static clone method.`
			);
			return replacementNode;
		}
	}
	return node;
}

/**
 * Takes an HTML element and adds the classNames passed within an array,
 * ignoring any non-string types. A space can be used to add multiple classes
 * eg. addClassNamesToElement(element, ['element-inner active', true, null])
 * will add both 'element-inner' and 'active' as classes to that element.
 * @param {HTMLElement} element - The element in which the classes are added
 * @param {Array<boolean | null | string>} classNames - An array defining the class names to add to the element
 */
export function addClassNamesToElement(element, ...classNames) {
	const classesToAdd = normalizeClassNames(...classNames);
	if (classesToAdd.length > 0) {
		element.classList.add(...classesToAdd);
	}
}

/**
 * Normalizes passed class names into an array of strings containing the class names.
 * @param {Array<boolean | null | string>} classNames - The class names to normalize
 * @return {Array<string>} An array of strings containing the class names
 */
export default function normalizeClassNames(...classNames) {
	const rval = [];
	for (const className of classNames) {
		if (className && typeof className === 'string') {
			for (const [s] of className.matchAll(/\S+/g)) {
				rval.push(s);
			}
		}
	}
	return rval;
}

/**
 * Retrieves an array of class names from the given theme object, caching the result for future use.
 *
 * @param {Object} classNamesTheme - The theme object containing class names.
 * @param {string} classNameThemeType - The type of class name to retrieve from the theme object.
 * @return {Array<string>|string} - An array of class names if the input was a string, otherwise returns the original value.
 */
export function getCachedClassNameArray(classNamesTheme, classNameThemeType) {
	if (classNamesTheme.__lexicalClassNameCache === undefined) {
		classNamesTheme.__lexicalClassNameCache = {};
	}
	const classNamesCache = classNamesTheme.__lexicalClassNameCache;
	const cachedClassNames = classNamesCache[classNameThemeType];
	if (cachedClassNames !== undefined) {
		return cachedClassNames;
	}
	const classNames = classNamesTheme[classNameThemeType];
	if (typeof classNames === 'string') {
		const classNamesArr = normalizeClassNames(classNames);
		classNamesCache[classNameThemeType] = classNamesArr;
		return classNamesArr;
	}
	return classNames;
}
