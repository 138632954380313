import { SectionItem } from 'datatalks-ui';
import { ColorPickerInput } from 'email-builder-components';
import { splitArrayIntoParts } from 'datatalks-utils';
import { merge } from 'datatalks-utils';
import { camelCase } from 'lodash-es';

export default (options = {}) => {
	const defaults = {
		numberOfInputs: 4,
		left: null,
		right: null,
		top: null,
		bottom: null,
		onLeftChange: null,
		onRightChange: null,
		onTopChange: null,
		onBottomChange: null,
		labelsTemplate(n, sides) {
			switch (n) {
				case 1:
					return null;
					break;
				case 2:
					return sides[0] === 'left'
						? `Margin X-axis`
						: `Margin Y-axis`;
					break;
				default:
					return `Margin ${sides[0]}`;
					break;
			}
		},
		triggerColorPickerChangeOnRender: true
	};

	options = merge(defaults, options);

	const sides = ['left', 'right', 'top', 'bottom'];

	const colorPickers = splitArrayIntoParts(sides, options.numberOfInputs).map(
		arr => {
			const onChange = cpi => {
				arr.forEach(side => {
					const onChange = options[camelCase(`on ${side} Change`)];
					if (typeof onChange === 'function')
						options[camelCase(`on ${side} Change`)](cpi.getColor());
				});
			};
			const cp = new ColorPickerInput({
				color: options[arr[0]],
				onChange
			});
			options.triggerColorPickerChangeOnRender && onChange(cp);
			return new SectionItem({
				label: options.labelsTemplate(options.numberOfInputs, arr),
				content: cp.getEl()
			}).getEl();
		}
	);

	return colorPickers;
};
