import { setContent } from 'datatalks-utils';
import ImageInput from '../../../common/imgFileInput/_imgFileInputPreview';
import {
	ToggleableSection,
	Input,
	InputLabel,
	SectionItem,
	UrlEditor
} from 'datatalks-ui';
import { TextEditor } from 'email-builder-components';
import { merge, extractPlainText, isHTML } from 'datatalks-utils';
import {
	textEditorCommonOptions,
	textEditorUpdateColorsOnGeneralChange
} from '../../../../common/functions/_textEditorCommonActions';
import modifiersCbs from '../../../common/_buttonEditorCbs';

export default (obj, item, options, customTrait = {}) => {
	const localize = obj.component.localize;
	const editor = obj.component.getEditor();
	const defaults = {
		accordionContentTemplate: null,
		accordionTitleTemplate: null,
		hasImageUploader: true,
		hasTitleEditor: true,
		hasDescriptionEditor: true,
		hasButtonEditor: true,
		buttonProp: 'buttonText',
		buttonHrefProp: 'buttonHref',
		buttonHrefStateProp: 'buttonHrefState',
		imageHasLinkProp: 'imageHasLink',
		imageHrefProp: 'imageHref',
		onImageChange: null,
		onImageDelete: null,
		useInput: false,
		descriptionEditorProp: `item${
			item.id != null ? `_${item.id}_` : ''
		}descriptionEditor`,
		titleEditorProp: `item${
			item.id != null ? `_${item.id}_` : ''
		}titleEditor`,
		buttonEditorProp: `item${
			item.id != null ? `_${item.id}_` : ''
		}buttonEditor`
	};

	options = merge(defaults, options);

	// TODO: Find a better path to merge components' trait options and display different variants of the same trait
	if (
		obj.component?.attributes?.traitOptions?.itemsListMutationOptions
			?.listItemElementTemplateOptions
	) {
		options = merge(
			options,
			obj.component.attributes.traitOptions.itemsListMutationOptions
				.listItemElementTemplateOptions
		);
	}

	const urlInput = document.createElement('input');
	urlInput.addEventListener('input', e => {
		obj.component.changeItemProp.call(
			obj.component,
			item,
			'href',
			e.target.value
		);
	});
	urlInput.className = 'eb-input';
	if (item.href) {
		urlInput.value = item.href;
	} else {
		urlInput.placeholder = item.url || 'URL';
	}

	// TODO: add validation to prevent accordion from being empty

	if (
		options.hasDescriptionEditor &&
		!customTrait[options.descriptionEditorProp]
	) {
		customTrait[options.descriptionEditorProp] = new TextEditor({
			initialContent: obj.component.getItemProp(item, 'description'),
			initialState: obj.component.getItemProp(item, 'descriptionState'),
			onChange: (html, editorState) => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'description',
					html
				);
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'descriptionState',
					editorState,
					false
				);
			},
			...textEditorCommonOptions(editor)
		});

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.descriptionEditorProp]
		);
	}

	if (options.hasTitleEditor && !customTrait[options.titleEditorProp]) {
		customTrait[options.titleEditorProp] = new TextEditor({
			initialContent: obj.component.getItemProp(item, 'title'),
			initialState: obj.component.getItemProp(item, 'titleState'),
			onChange: (html, editorState) => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'title',
					html
				);
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'titleState',
					editorState,
					false
				);
			},
			...textEditorCommonOptions(editor)
		});

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.titleEditorProp]
		);
	}

	const toolbarOptions = modifiersCbs(obj, item);

	if (options.hasButtonEditor && !customTrait[options.buttonEditorProp]) {
		const buttonText = obj.component.getItemProp(
			item,
			options.buttonProp || 'buttonText'
		);
		const initialContent = isHTML(buttonText)
			? extractPlainText(buttonText)
			: buttonText;
		if (initialContent !== buttonText)
			obj.component.changeItemProp.call(
				obj.component,
				item,
				options.buttonProp || 'buttonText',
				initialContent
			);
		customTrait[options.buttonEditorProp] = new TextEditor({
			initialContent: isHTML(buttonText)
				? extractPlainText(buttonText)
				: buttonText,
			// initialState: obj.component.getItemProp(item, 'buttonState'),
			onChange: (html, editorState) => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					options.buttonProp || 'buttonText',
					html
				);
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'buttonState',
					editorState,
					false
				);
			},
			...merge(
				{
					toolbarOptions
				},
				textEditorCommonOptions(editor, { isButton: true })
			)
		});

		customTrait[options.buttonEditorProp].toolbar.on(
			'change:line-height',
			lineHeight => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'buttonLineHeight',
					lineHeight
				);
			}
		);

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.buttonEditorProp],
			{
				isButton: true
			}
		);
	}

	const imageUploader = new ImageInput({
		image: obj.component.getItemProp.call(obj.component, item, 'imgSrc'),
		useInput: options.useInput,
		callbacks: {
			wrapperClick: (fileInput, e) => {
				const editor = obj.component.getEditor();
				editor.waitForAssets((opts, sender, a) => {
					fileInput.set(sender.imgSrc, sender.imgName);
				});
			},
			change: (fileInput, imgSrc) => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'imgSrc',
					imgSrc
				);
				if (typeof options.onImageChange === 'function')
					options.onImageChange.call(
						null,
						obj.component,
						item,
						fileInput
					);
			},
			delete: () => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					'imgSrc',
					null
				);
				if (typeof options.onImageDelete === 'function')
					options.onImageDelete.call(null, obj.component, item);
			}
		}
	});

	const imageLinkSection = new ToggleableSection({
		label: localize('Add a link to your image'),
		content: new SectionItem({
			label: localize('Add a URL'),
			content: new Input({
				onChange: (e, input) =>
					obj.component.changeItemProp.call(
						obj.component,
						item,
						options.imageHrefProp,
						input.value
					),
				initialValue: obj.component.getItemProp(
					item,
					options.imageHrefProp
				)
			}).getEl()
		}).getEl(),
		toggleableContent: true,
		startOpen: obj.component.getItemProp(item, options.imageHasLinkProp),
		onToggle: isActive =>
			obj.component.changeItemProp.call(
				obj.component,
				item,
				options.imageHasLinkProp,
				isActive
			)
	}).getEl();

	let objects = [];

	if (
		editor.getEmailBuilderOptions()?.textEditorOptions?.toolbarOptions
			?.objects.length
	)
		objects = editor
			.getEmailBuilderOptions()
			?.textEditorOptions?.toolbarOptions?.objects.map(obj => ({
				...obj,
				urlEditorLocationType: 'newPopup'
			}));

	const urlEditorToolbarOptions = {
		...(editor.getEmailBuilderOptions()?.textEditorOptions
			?.toolbarOptions || {}),
		objects
	};

	const buttonUrlInputLabel = new InputLabel({
		input: new UrlEditor({
			initialContent: obj.component.getItemProp(
				item,
				options.buttonHrefProp || 'buttonHref'
			),
			initialState:
				obj.component.getItemProp(
					item,
					options.buttonHrefStateProp || 'buttonHrefState'
				) || null,
			toolbarOptions: urlEditorToolbarOptions,
			onChange: (url, state) => {
				obj.component.changeItemProp.call(
					obj.component,
					item,
					options.buttonHrefProp || 'buttonHref',
					url
				);
				obj.component.changeItemProp.call(
					obj.component,
					item,
					options.buttonHrefStateProp || 'buttonHrefState',
					state
				);
			}
		}).getEl(),
		label: localize('Button Link')
	}).getEl();

	return setContent(document.createElement('div'), [
		options.hasImageUploader &&
			new ToggleableSection({
				label: localize('Upload your image'),
				content: [imageUploader.getEl(), imageLinkSection],
				toggleableContent: true,
				startOpen: obj.component.getItemProp(item, 'displayImage'),
				onToggle: isActive =>
					obj.component.changeItemProp.call(
						obj.component,
						item,
						'displayImage',
						isActive
					)
			}).getEl(),
		options.hasTitleEditor &&
			new ToggleableSection({
				label: localize('Title'),
				content: customTrait[options.titleEditorProp].getEl(),
				toggleableContent: true,
				startOpen: obj.component.getItemProp(item, 'displayTitle'),
				onToggle: isActive =>
					obj.component.changeItemProp.call(
						obj.component,
						item,
						'displayTitle',
						isActive
					)
			}).getEl(),
		options.hasDescriptionEditor &&
			new ToggleableSection({
				label: localize('Description'),
				content: customTrait[options.descriptionEditorProp].getEl(),
				toggleableContent: true,
				startOpen: obj.component.getItemProp(
					item,
					'displayDescription'
				),
				onToggle: isActive =>
					obj.component.changeItemProp.call(
						obj.component,
						item,
						'displayDescription',
						isActive
					)
			}).getEl(),
		options.hasButtonEditor &&
			new ToggleableSection({
				label:
					localize('components.names.button', true) ||
					localize('Button'),
				content: [
					buttonUrlInputLabel,
					new InputLabel({
						input: customTrait[options.buttonEditorProp].getEl(),
						label: localize('Button Text')
					}).getEl(),
					setContent(
						document.createElement('figcaption'),
						localize(
							'Please check the template editor for the style preview'
						)
					) // TODO: delete this when plain text mode can preview styles
				],
				toggleableContent: true,
				startOpen: obj.component.getItemProp(item, 'displayButton'),
				onToggle: isActive =>
					obj.component.changeItemProp.call(
						obj.component,
						item,
						'displayButton',
						isActive
					)
			}).getEl()
	]);
};
