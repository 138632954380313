/* eslint-disable require-jsdoc */
import { $getSelection, $isRangeSelection, $isTextNode } from 'lexical';
import { $createParagraphNode } from './_ParagraphNode';
import { $getNearestBlockElementAncestorOrThrow } from '@lexical/utils';
import { $isQuoteNode } from '@lexical/rich-text';
import { $isHeadingNode } from './_HeadingNode';

export default function clearFormattingPlugin(editor) {
	editor.update(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const anchor = selection.anchor;
			const focus = selection.focus;
			const nodes = selection.getNodes();

			if (anchor.key === focus.key && anchor.offset === focus.offset) {
				return;
			}

			nodes.forEach((node, idx) => {
				// We split the first and last node by the selection
				// So that we don't format unselected text inside those nodes
				if ($isTextNode(node)) {
					if (idx === 0 && anchor.offset !== 0) {
						node = node.splitText(anchor.offset)[1] || node;
					}
					if (idx === nodes.length - 1) {
						node = node.splitText(focus.offset)[0] || node;
					}

					if (node.__style !== '') {
						node.setStyle('');
					}
					if (node.__format !== 0) {
						node.setFormat(0);
						$getNearestBlockElementAncestorOrThrow(node).setFormat(
							''
						);
					}
				} else if ($isHeadingNode(node) || $isQuoteNode(node)) {
					node.replace($createParagraphNode(), true);
				}

				// else if ($isDecoratorBlockNode(node)) {
				// 	node.setFormat('');
				// }
			});
		}
	});
}
