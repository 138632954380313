import actionsDropdown from './assets/panels/assets/_actionsDropdown';
import titleInput from './assets/panels/assets/_titleInput';
import zoomSlider from './assets/panels/assets/_zoomSlider';
import { translationFramework } from 'email-builder-plugin';

export default emailBuilder => {
	const { editor } = emailBuilder;
	const pluginOpts = emailBuilder.getOptions();

	editor.Panels.getPanels().reset(
		[
			{
				id: 'devices',
				buttons: []
			},
			{
				id: 'save-changes',
				visible: false,
				buttons: [
					{
						id: 'save-changes-icon',
						className: 'save-changes__icon',
						label: '<i class="eb-i eb-i-save-line eb-i--xl"></i>',
						context: 'save-changes'
					},
					{
						id: 'save-changes-title',
						className: 'save-changes__title',
						label: translationFramework.getTranslation(
							'prompts.save.title'
						),
						context: 'save-changes'
					},
					{
						id: 'save-changes-subtitle',
						className: 'save-changes__subtitle',
						label: translationFramework.getTranslation(
							'prompts.save.message'
						),
						context: 'save-changes'
					},
					{
						id: 'save-changes-button',
						className: 'save-changes__button',
						label: translationFramework.getTranslation(
							'actions.save'
						),
						command: {
							run(editor, sender) {
								if (!pluginOpts?.preventDefaultActions?.save) {
									emailBuilder.store();
								}
								if (typeof pluginOpts?.onSave === 'function') {
									pluginOpts.onSave(emailBuilder);
								}
								editor.stopCommand('show-save-panel');
							}
						},
						context: 'save-changes'
					}
				]
			},
			(pluginOpts.titleConfig?.title ||
				pluginOpts.titleConfig?.titleOnChange) && {
				id: 'title',
				buttons: [
					{
						active: false,
						id: 'titleInput',
						className: 'eb-title-input',
						label: titleInput(emailBuilder)
					}
				]
			},
			{
				id: 'navigate',
				buttons: [
					{
						// active: false,
						id: 'go-back-button',
						className: 'eb-go-back-button',
						label: '<i class="eb-i eb-i-arrow-left-s-line eb-i--xl eb-go-back-button__icon"></i>',
						context: 'go-back',
						command: 'go-back'
					},
					{
						id: 'undo',
						className: 'eb-undo-button',
						label: '<i class="eb-i eb-i-arrow-go-back-line eb-i--lg eb-undo-button__icon"></i>',
						context: 'undo',
						command: 'undo',
						disable: !editor.UndoManager.hasUndo()
					},
					{
						id: 'redo',
						className: 'eb-redo-button',
						label: '<i class="eb-i eb-i-arrow-go-forward-line eb-i--lg eb-redo-button__icon"></i>',
						context: 'redo',
						command: 'redo',
						disable: !editor.UndoManager.hasRedo()
					}
				]
			},
			{
				id: 'actions',
				buttons: [
					{
						id: 'preview-email',
						command: 'preview-email',
						className: 'eb-pn-btn-action eb-send-preview-button',
						label: `<button class="eb-btn eb-btn--link eb-btn--icon" title="${translationFramework.getTranslation(
							'actions.sendPreview'
						)}"><i class="eb-i eb-i-send-plane-line eb-i--lg eb-html-preview-button__icon"></i><span class="eb-pn-btn__title">${translationFramework.getTranslation(
							'actions.sendPreview'
						)}</span></button>`,
						useAction:
							typeof pluginOpts.onPreviewEmail === 'function'
					},
					{
						id: 'html-preview',
						className: 'eb-pn-btn-action eb-html-view-button',
						label: `<button class="eb-btn eb-btn--link eb-btn--icon" title="${translationFramework.getTranslation(
							'actions.preview'
						)}"><i class="eb-i eb-i-device-line eb-i--lg eb-html-preview-button__icon"></i><span class="eb-pn-btn__title">${translationFramework.getTranslation(
							'actions.preview'
						)}</span></button>`,
						context: 'html-preview',
						command: 'html-preview'
					},
					{
						active: false,
						id: 'actions',
						className: 'eb-actions-dropdown',
						label: actionsDropdown(emailBuilder),
						attributes: { title: 'Import Configuration' }
					}
				]
			},
			{
				id: 'traits-view',
				visible: false,
				buttons: [
					{
						active: true,
						id: 'open-content-traits',
						label: 'Content',
						command: 'open-content-traits'
					},
					{
						active: false,
						id: 'open-style-traits',
						label: 'Style',
						command: 'open-style-traits'
					}
				]
			},
			{
				id: 'main-view',
				buttons: [
					{
						active: true,
						id: 'open-blocks-c',
						label:
							translationFramework.getTranslation(
								'sections.components'
							) || 'Components',
						command: 'open-blocks'
					},
					{
						active: false,
						id: 'open-general',
						label:
							translationFramework.getTranslation(
								'sections.generalSettings'
							) || 'General settings',
						command: 'open-general'
					}
				]
			},
			{
				id: 'context',
				buttons: [
					{
						active: false,
						id: 'context-go-back-icon',
						className: 'context__icon context__icon--hidden',
						label: '<i class="eb-i eb-i-arrow-left-s-line eb-i--xl"></i>',
						context: 'context',
						command: 'change-context'
					},
					{
						id: 'context-title',
						className: 'context__title',
						label:
							emailBuilder.translationFramework.getTranslation(
								pluginOpts.panelsLabels.generalContext
							) || 'Edit Email',
						context: 'context'
					}
				]
			},
			{
				id: 'zoom',
				visible: false,
				buttons: [
					{
						id: 'zoom',
						className: 'zoom-slider',
						label: zoomSlider(emailBuilder)
					}
				]
			}
		].filter(panel => panel)
	);
};
