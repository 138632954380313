import { merge } from 'datatalks-utils';
import { setContent } from 'datatalks-utils';
import { Accordion, SectionItem } from 'datatalks-ui';
import { ColorPickerInput } from 'email-builder-components';
import textAlignment from './buttonAccordion/_buttonAlignment';

export default (obj, options = {}, customTrait = {}) => {
	const localize = obj.component.localize;
	const defaults = {
		textAlignmentOptions: {
			componentProperty: 'textAlignment'
		},
		accordion: {
			title: localize('components.names.text', true) || localize('Text'),
			color: 'grey',
			type: 'extend'
		},
		styleObjPropName: 'textStyle',
		sectionsLabel: {
			color:
				localize('traits.styles.properties.color', true) ||
				localize('Color'),
			alignment:
				localize('traits.styles.sections.alignment', true) ||
				localize('Alignment')
		},
		colorPickerProp: 'textCp'
	};

	options = merge(defaults, options);

	if (!customTrait[options.colorPickerProp])
		customTrait[options.colorPickerProp] = new ColorPickerInput({
			color: obj.component.get(options.styleObjPropName).color,
			emptyColor: () =>
				obj.component.getEditor()?.getStyleRules('wrapper', 'color') ||
				null,
			emptyColorLabel: localize('General text color'),
			onChange: (cpi, ev) => {
				obj.component.setAndRerender(options.styleObjPropName, {
					...obj.component.get(options.styleObjPropName),
					color: cpi.getColor()
				});
			}
		});

	const textAccordionContent = setContent(document.createElement('div'), [
		new SectionItem({
			label: options.sectionsLabel.color,
			content: customTrait[options.colorPickerProp].getEl()
		}).getEl(),
		new SectionItem({
			label: options.sectionsLabel.alignment,
			content: textAlignment(obj, options.textAlignmentOptions).getEl()
		}).getEl()
	]);

	const textAccordion = new Accordion({
		title: options.accordion.title,
		content: textAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return textAccordion;
};
