/* eslint-disable require-jsdoc */
import { HeadingNode as LexicalHeadingNode } from '@lexical/rich-text';
import { isElement } from 'datatalks-utils';
import { $applyNodeReplacement, addClassNamesToElement } from './_lexicalUtils';
import { parseStyleString, cssStyleDeclarationToObject } from 'datatalks-utils';

export class HeadingNode extends LexicalHeadingNode {
	constructor(tag, key) {
		super(tag, key);
	}

	static getType() {
		return 'heading-node';
	}

	static clone(node) {
		return new HeadingNode(node.__tag, node.__key);
	}

	static importJSON(serializedNode) {
		return $createHeadingNode(serializedNode.tag).updateFromJSON(
			serializedNode
		);
	}

	createDOM(config) {
		const tag = this.__tag;
		const element = document.createElement(tag);
		const theme = config.theme;
		const classNames = theme.heading;
		if (classNames !== undefined) {
			const className = classNames[tag];
			addClassNamesToElement(element, className);
		}
		if (this.getStyle()) {
			element.style = this.getStyle();
		}
		return element;
	}

	exportDOM(editor) {
		const { element } = super.exportDOM(editor);

		if (isElement(element)) {
			if (this.isEmpty()) {
				element.append(document.createElement('br'));
			}

			const formatType = this.getFormatType();
			element.style.textAlign = formatType || 'left';

			const direction = this.getDirection();
			if (direction) {
				element.dir = direction;
			}
		}

		return {
			element
		};
	}

	updateDOM(prevNode, dom, config) {
		return (
			cssStyleDeclarationToObject(dom.style) !==
				parseStyleString(prevNode.getStyle()) ||
			prevNode.__tag !== this.__tag
		);
	}

	exportJSON() {
		return {
			...super.exportJSON(),
			type: 'heading-node',
			tag: this.getTag()
		};
	}
}

export function $isHeadingNode(node) {
	return node instanceof HeadingNode;
}

export function $createHeadingNode(headingTag, editor) {
	return $applyNodeReplacement(new HeadingNode(headingTag), editor);
}
