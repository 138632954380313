/* eslint-disable require-jsdoc */

import { $patchStyleText } from '@lexical/selection';
import {
	FORMAT_TEXT_COMMAND,
	$getSelection,
	$isRangeSelection,
	$isNodeSelection,
	$getRoot
} from 'lexical';
import { ParagraphNode } from './_ParagraphNode';
import { $isHeadingNode, HeadingNode } from './_HeadingNode';
import {
	TOGGLE_LINK_COMMAND,
	$isLinkNode as $isLexicalLinkNode
} from '@lexical/link';
import { $isLinkNode } from './_LinkNode';
import { $getNearestNodeOfType } from '@lexical/utils';
import { $isListNode, ListNode } from '@lexical/list';
import getFormatters from './_formatters';
import getModifiers from './_modifiers';
import { patchStyleObjectNode, objectPatchStyle } from './_utils';
import {
	Dropdown,
	DropdownItem,
	DropdownButtonItem,
	DropdownButton,
	Button,
	ColorPickerInput,
	Popup,
	UrlEditor
} from 'datatalks-ui';
import { getIcon } from 'datatalks-icons';
import {
	webFonts,
	webSafeFonts,
	unquoteString,
	setContent,
	addClassesString,
	EventEmitter,
	merge
} from 'datatalks-utils';
import clearFormattingPlugin from './_clearFormattingPlugin';
import { TextEditorDropdown } from './_TextEditorDropdown';
import { capitalize } from 'lodash-es';
import TextEditorPopup from './_TextEditorPopup';
import ToolbarObject from './_ToolbarObject';

function isLinkNode(node) {
	return $isLinkNode(node) || $isLexicalLinkNode(node);
}

export default class TextEditorToolbar {
	constructor(options = {}) {
		const toolbar = this;

		const defaults = {
			editor: null,
			textEditor: null,
			useRichText: true,
			useFormatters: {
				code: true,
				paragraph: true,
				h1: true,
				h2: true,
				h3: true,
				h4: true,
				h5: true,
				h6: true,
				numberedList: true,
				bulletList: true
			},
			startBold: {
				code: false,
				paragraph: false,
				h1: true,
				h2: true,
				h3: true,
				h4: true,
				h5: true,
				h6: true,
				bulletList: false,
				numberedList: false
			},
			useModifiers: {
				bold: true,
				italic: true,
				strikethrough: true,
				underline: true,
				superscript: false,
				code: false,
				alignLeft: true,
				alignCenter: true,
				alignRight: true,
				alignJustify: true
			},
			modifiersCb: {
				bold: null,
				italic: null,
				strikethrough: null,
				underline: null,
				superscript: null,
				code: null,
				alignLeft: null,
				alignCenter: null,
				alignRight: null,
				alignJustify: null
			},
			modifiersEval: {
				bold: null,
				italic: null,
				strikethrough: null,
				underline: null,
				superscript: null,
				code: null,
				alignLeft: null,
				alignCenter: null,
				alignRight: null,
				alignJustify: null
			},
			useLink: true,
			useLineHeight: true,
			useFontSize: true,
			useColor: true,
			useFont: true,
			useClearFormat: true,
			useObjects: true,
			clearFormattingIconSize: 'lg',
			linkIconSize: 'lg',
			getFormatters,
			getModifiers,
			classPrefix: 'eb-',
			className: 'prosetyper-toolbar',
			dropdownClassName: 'prosetyper-dropdown',
			dropdownItemClassName: 'prosetyper-dropdown-item',
			dropdownItemTemplate: ({ icon, label }) => {
				return [
					addClassesString(
						setContent(document.createElement('div'), icon),
						'eb-prosetyper__icon eb-prosetyper-dropdown-item__icon'
					),
					addClassesString(
						setContent(document.createElement('div'), label),
						'eb-prosetyper__label eb-prosetyper-dropdown-item__label'
					)
				];
			},
			dropdownsOptions: {
				arrowSize: 'lg'
			},
			lineHeightValues: [0.8, 1, 1.15, 1.5, 1.75, 2, 2.5, 3, 4, 5],
			lineHeightBySelection: false,
			lineHeightPlaceholder: 'Line height',
			fontsDropdownPlaceholder: 'Set font',
			fontSizesDropdownPlaceholder: 'Set font size',
			fontSizeValues: [
				'8px',
				'10px',
				'12px',
				'14px',
				'16px',
				'24px',
				'32px',
				'48px',
				'64px',
				'72px'
			],
			extraDropdowns: [],
			evalAllowExtraDropdown: null,
			boldLinks: true,
			defaultColor: '#000',
			objects: [],
			plainTextOptions: {
				font: null,
				color: null,
				fontSize: null
			},
			PlainTextCallbacks: {
				font: null,
				color: null,
				fontSize: null
			}
		};

		defaults.clearFormattingIcon = getIcon('format-clear', {
			size:
				options.clearFormattingIconSize ||
				defaults.clearFormattingIconSize
		});

		defaults.linkIcon = getIcon('link', {
			size: options.linkIconSize || defaults.linkIconSize
		});

		this.options = merge(defaults, options);

		this.className = `${this.options.classPrefix}${this.options.className}`;
		this.dropdownClassName = `${this.options.classPrefix}${this.options.dropdownClassName}`;
		this.dropdownItemClassName = `${this.options.classPrefix}${this.options.dropdownItemClassName}`;

		if (!this.options.editor)
			throw new Error('TextEditorToolbar: editor is required');

		this.editor = this.options.editor;
		this.textEditor = this.options.textEditor;
		this.element = this.options.element || document.createElement('div');
		this.element.classList.add(this.className);
		this.element.addEventListener('click', e => {
			toolbar.editor.focus();
		});
		this.formatters = [];
		this.formattersDropdown = null;
		this.fonts = [];
		this.fontsDropdown = null;
		this.fontSizesDropdown = null;
		this.fontSizes = [];
		this.lineHeightsDropdown = null;
		this.lineHeights = [];
		this.modifiers = [];
		this.aligners = [];
		this.clearFormattingButton = null;
		this.linkButton = null;
		this.extraDropdowns = {
			bottom: [],
			top: []
		};
		this.eventEmitter = new EventEmitter();
		this.activePopups = [];
		this.plainTextState = { ...this.options.plainTextOptions };

		this.init();
	}

	init() {
		this.on('selectionChange', this.handleSelectionChange.bind(this));
		this.createModifiers();
		this.createAligners();
		if (this.options.useRichText) this.createFormatters();
		if (this.options.useFont) this.createFonts();
		if (this.options.useLineHeight) this.createLineHeights();
		if (this.options.useFontSize) this.createFontSizes();
		if (this.options.useColor) this.createColorPicker();
		if (this.options.useClearFormat) this.createFormattingClearButton();
		if (this.options.useLink) {
			this.createLinkButton();
			this.createLinkEditor();
		}
		if (this.options.useObjects) {
			this.convertObjects();
		}
		this.createExtraDropdowns();
		this.fillTopToolbar();
	}

	createFormatters() {
		const toolbar = this;
		const formatters = toolbar.options.getFormatters(
			toolbar,
			toolbar.editor,
			{
				formattersVisibility: toolbar.options.useFormatters,
				startBold: toolbar.options.startBold
			}
		);
		Object.values(formatters).forEach(formatter => {
			const { name, format, icon } = formatter;
			const item = new DropdownItem({
				content: toolbar.options.dropdownItemTemplate({
					icon,
					label: name
				}),
				onClick: format,
				extendedClasses: `${toolbar.dropdownItemClassName} ${toolbar.className}__dropdown-item`
			});
			toolbar.formatters.push({
				...formatter,
				item
			});
		});
		toolbar.formattersDropdown = new Dropdown({
			arrowSize: toolbar.options.dropdownsOptions.arrowSize,
			extendedClasses: `${toolbar.dropdownClassName} ${toolbar.className}__dropdown`,
			items: toolbar.formatters.map(f => f.item)
		});
	}

	createExtraDropdowns() {
		this.options.extraDropdowns.forEach(dropdown => {
			if (!(dropdown instanceof TextEditorDropdown)) {
				dropdown = new TextEditorDropdown(dropdown);
			}
			if (
				(typeof this.options.evalAllowExtraDropdown === 'function' &&
					this.options.evalAllowExtraDropdown.call(this, dropdown)) ||
				!this.options.evalAllowExtraDropdown
			)
				this.createExtraDropdown(dropdown);
		});
	}

	createExtraDropdown(dropdown) {
		const toolbar = this;
		toolbar.extraDropdowns[dropdown.position].push(
			new DropdownButton({
				...toolbar.options.dropdownsOptions,
				...dropdown.dropdownOptions,
				items: dropdown.dropdownOptions.items.map(dropItem => {
					return new DropdownButtonItem({
						...(toolbar.options.dropdownsOptions.itemsOptions ||
							{}),
						...dropItem,
						extendedClasses: `${
							dropItem.extendedClasses
								? dropItem.extendedClasses + ' '
								: ''
						}${toolbar.dropdownItemClassName} ${
							toolbar.className
						}__dropdown-item`,
						onClick: (item, itemValue) => {
							if (typeof dropItem.onClick === 'function')
								dropItem.onClick.call(toolbar, {
									toolbar,
									item,
									itemValue,
									toolbar,
									editor: toolbar.editor
								});
						},
						onSelect: (item, itemValue) => {
							if (typeof dropItem.onSelect === 'function')
								dropItem.onSelect.call(toolbar, {
									toolbar,
									item,
									itemValue,
									toolbar,
									editor: toolbar.editor
								});
						},
						onDeselect: (item, itemValue) => {
							if (typeof dropItem.onDeselect === 'function')
								dropItem.onDeselect.call(toolbar, {
									toolbar,
									item,
									itemValue,
									toolbar,
									editor: toolbar.editor
								});
						}
					});
				}),
				extendedClasses: `${
					dropdown.dropdownOptions.extendedClasses
						? dropdown.dropdownOptions.extendedClasses + ' '
						: ''
				}${toolbar.dropdownClassName} ${toolbar.className}__dropdown`
			})
		);
	}

	createFonts() {
		const toolbar = this;
		[...webSafeFonts, ...webFonts].sort().forEach(font => {
			const item = new DropdownItem({
				content: webFonts.includes(font)
					? `${capitalize(font)} *`
					: capitalize(font),
				onClick: () => {
					if (toolbar.options.useRichText) {
						this.editor.update(() => {
							const selection = $getSelection();
							if (
								!$isRangeSelection(selection) &&
								!$isNodeSelection(selection)
							) {
								return false;
							}
							const patch = {
								'font-family': font
							};
							$patchStyleText(selection, patch);
							patchStyleObjectNode(selection, patch);
							return true;
						});
					} else {
						this.plainTextState.font = font.name;
						if (toolbar.options.PlainTextCallbacks.font) {
							toolbar.options.PlainTextCallbacks.font(
								font,
								toolbar
							);
						}
					}
				},
				extendedClasses: `${toolbar.dropdownItemClassName} ${toolbar.className}__dropdown-item`
			});

			toolbar.fonts.push({
				name: font,
				item
			});
		});
		toolbar.fontsDropdown = new Dropdown({
			arrowSize: toolbar.options.dropdownsOptions.arrowSize,
			extendedClasses: `${toolbar.dropdownClassName} ${toolbar.className}__dropdown`,
			items: toolbar.fonts.map(f => f.item),
			placeholder: this.options.fontsDropdownPlaceholder || 'Set font'
		});
	}

	editorAddLink(href, setBold = this.options.boldLinks) {
		this.editor.dispatchCommand(TOGGLE_LINK_COMMAND, href);
		if (setBold) {
			let isBold;
			this.editor.update(() => {
				isBold = $getSelection().hasFormat('bold');
				return isBold;
			});
			if (!isBold)
				this.editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
		}
	}

	editorAddHtml(html) {
		this.textEditor.addHtml(html);
	}

	editorAddObjectNode(options) {
		return this.textEditor.editorAddObjectNode(options);
	}

	editorAddText(text) {
		this.editor.update(() => {
			const selection = $getSelection();
			if (selection) {
				selection.insertText(text);
			}
		});
	}

	createFontSizes() {
		const toolbar = this;
		this.options.fontSizeValues.forEach(size => {
			const item = new DropdownItem({
				content: size,
				onClick: () => {
					if (toolbar.options.useRichText) {
						this.editor.update(() => {
							const selection = $getSelection();
							if (
								!$isRangeSelection(selection) &&
								!$isNodeSelection(selection)
							) {
								return false;
							}
							const patch = {
								'font-size': size
							};
							$patchStyleText(selection, patch);
							patchStyleObjectNode(selection, patch);
							return true;
						});
					} else {
						this.plainTextState.fontSize = size;
						if (
							typeof toolbar.options.PlainTextCallbacks
								.fontSize === 'function'
						) {
							toolbar.options.PlainTextCallbacks.fontSize(
								size,
								toolbar
							);
						}
					}
				},
				extendedClasses: `${toolbar.dropdownItemClassName} ${toolbar.className}__dropdown-item`
			});

			toolbar.fontSizes.push({
				name: size,
				item
			});
		});
		toolbar.fontSizesDropdown = new Dropdown({
			arrowSize: toolbar.options.dropdownsOptions.arrowSize,
			extendedClasses: `${toolbar.dropdownClassName} ${toolbar.className}__dropdown`,
			items: toolbar.fontSizes.map(fs => fs.item),
			placeholder:
				this.options.fontSizesDropdownPlaceholder || 'Set font size'
		});
	}

	createLineHeights() {
		const toolbar = this;
		toolbar.options.lineHeightValues.forEach(height => {
			const item = new DropdownItem({
				content: height,
				onClick: () => {
					this.editor.update(() => {
						const selection = $getSelection();
						if (!$isRangeSelection(selection)) {
							return false;
						}
						if (toolbar.options.lineHeightBySelection) {
							$patchStyleText(selection, {
								'line-height': height
							});
						} else {
							if (
								selection &&
								selection.anchor &&
								selection.anchor.getNode
							) {
								let node = selection.anchor.getNode();
								if (node && node.getParent) {
									node =
										toolbar.textEditor.findClosestNodeOfType(
											node,
											HeadingNode
										) ||
										toolbar.textEditor.findClosestNodeOfType(
											node,
											ParagraphNode
										);
									if (node) {
										objectPatchStyle(node, {
											'line-height': height
										});
									}
								}
							}
						}
						toolbar.eventEmitter.emit('change:line-height', height);
						return true;
					});
				},
				extendedClasses: `${toolbar.dropdownItemClassName} ${toolbar.className}__dropdown-item`
			});

			toolbar.lineHeights.push({
				name: height,
				item
			});
		});
		toolbar.lineHeightsDropdown = new Dropdown({
			arrowSize: toolbar.options.dropdownsOptions.arrowSize,
			extendedClasses: `${toolbar.dropdownClassName} ${toolbar.className}__dropdown`,
			items: toolbar.lineHeights.map(lh => lh.item),
			placeholder: toolbar.options.lineHeightPlaceholder || 'Line height'
		});
	}

	createModifiers() {
		const toolbar = this;
		Object.values(toolbar.options.getModifiers(toolbar.editor))
			.filter(mod => toolbar.options.useModifiers[mod.name])
			.filter(({ category }) => category === 'text')
			.forEach(modifier => {
				toolbar.createModifier(modifier);
			});
	}

	createModifier(modifier) {
		const toolbar = this;
		const { icon, type, payload, label } = modifier;
		const button = document.createElement('button');
		button.className = `${this.className}__button`;

		if (icon) {
			setContent(button, icon);
		} else {
			button.textContent = label || payload;
		}

		button.addEventListener('click', () => {
			if (toolbar.options.useRichText) {
				this.editor.dispatchCommand(type, payload);
			}
			toolbar.eventEmitter.emit(`modify`, modifier);
			toolbar.eventEmitter.emit(`modify:${payload}`);
			if (typeof toolbar.options.modifiersCb[payload] === 'function') {
				toolbar.options.modifiersCb[payload](toolbar);
			}
		});
		this.modifiers.push({
			...modifier,
			button,
			active: false
		});
	}

	createAligners() {
		const toolbar = this;
		Object.values(toolbar.options.getModifiers(toolbar.editor))
			.filter(mod => toolbar.options.useModifiers[mod.name])
			.filter(({ category }) => category === 'alignment')
			.forEach(aligner => {
				toolbar.createAligner(aligner);
			});
	}

	createAligner(aligner) {
		const toolbar = this;
		const { icon, type, payload, label, name } = aligner;
		const button = document.createElement('button');
		button.className = `${toolbar.className}__button`;

		if (icon) {
			setContent(button, icon);
		} else {
			button.textContent = label || payload;
		}

		button.addEventListener('click', () => {
			if (toolbar.options.useRichText) {
				toolbar.editor.dispatchCommand(type, payload);
			}
			toolbar.eventEmitter.emit(`align`, aligner);
			toolbar.eventEmitter.emit(`align:${payload}`);
			if (typeof toolbar.options.modifiersCb[name] === 'function') {
				toolbar.options.modifiersCb[name](toolbar);
			}
		});
		toolbar.aligners.push({
			...aligner,
			button,
			active: false
		});
	}

	createColorPicker() {
		const toolbar = this;
		toolbar.textColorPicker = new ColorPickerInput({
			color: toolbar.options.defaultColor || '#000',
			emptyColor: () => null,
			noInput: true,
			extendedClasses: `${toolbar.className}__color-picker`,
			onChange: (cpi, ev) => {
				if (toolbar.options.useRichText) {
					toolbar.editor.update(() => {
						const selection = $getSelection();
						if (
							!$isRangeSelection(selection) &&
							!$isNodeSelection(selection)
						) {
							return false;
						}
						$patchStyleText(selection, {
							color: cpi.getColor()
						});
						patchStyleObjectNode(selection, {
							color: cpi.getColor()
						});
						return true;
					});
					toolbar.editor.read(() => {
						toolbar.eventEmitter.emit(
							'change:color',
							cpi.getColor()
						);
					});
				} else {
					toolbar.plainTextState.color = cpi.getColor();
					toolbar.eventEmitter.emit('change:color', cpi.getColor());
					if (toolbar.options.PlainTextCallbacks.color) {
						toolbar.options.PlainTextCallbacks.color(
							cpi.getColor(),
							toolbar
						);
					}
				}
			}
		});
	}

	createFormattingClearButton() {
		const button = document.createElement('button');
		button.className = `${this.className}__button`;

		if (this.options.clearFormattingIcon) {
			setContent(button, this.options.clearFormattingIcon);
		} else {
			button.textContent =
				this.options.clearFormattingLabel || 'Clear all formatting';
		}

		button.addEventListener('click', () => {
			this.clearFormatting();
		});
		this.clearFormattingButton = button;
	}

	createLinkButton() {
		const button = document.createElement('button');
		button.className = `${this.className}__button`;

		if (this.options.linkIcon) {
			setContent(button, this.options.linkIcon);
		} else {
			button.textContent = this.options.linkLabel || 'Insert link';
		}
		this.linkButton = button;
	}

	createLinkEditor() {
		const toolbar = this;
		toolbar.UrlEditor = new UrlEditor({
			toolbarOptions: {
				extraDropdowns: toolbar.options.extraDropdowns,
				objects: toolbar.options.objects
			},
			parentEditor: toolbar.textEditor
		});
		toolbar.UrlEditorElement = toolbar.UrlEditor.getEl();
		toolbar.linkPopupContent = addClassesString(
			setContent(document.createElement('div'), [
				addClassesString(
					setContent(
						document.createElement('label'),
						'Destination URL'
					),
					`${this.className}__label`
				),
				toolbar.UrlEditorElement
			]),
			`${this.className}__url-editor-content`
		);

		toolbar.linkPopup = new TextEditorPopup(this, {
			title: 'Link',
			extendedClasses: `${toolbar.className}__link-popup`,
			content: [
				toolbar.linkPopupContent,
				new Button({
					content: 'Save',
					onClick: () => {
						toolbar.handleLinkAdd.call(
							toolbar,
							toolbar.UrlEditor.getTextContent(),
							toolbar.UrlEditor.getEditorState()
						);
						toolbar.linkPopup.popup.close();
					}
				}).getEl()
			],
			trigger: toolbar.linkButton
		});
		toolbar.linkEditor = toolbar.linkPopup.popupEl;
	}

	linkPopupAddContent(content) {
		if (!this.linkPopupContent) {
			console.warn('No link popup found.');
			return;
		}
		setContent(this.linkPopupContent, content, true);
	}

	convertObjects() {
		const toolbar = this;
		toolbar.objects = toolbar.options.objects.map(object => {
			if (!(object instanceof ToolbarObject)) {
				return new ToolbarObject(toolbar, object);
			} else {
				return object;
			}
		});
		toolbar.textEditor.on('load', (textEditor, editorState) => {
			const objectNodes = [];
			editorState.read(() => {
				const root = $getRoot();
				const traverse = node => {
					if (node.getType() === 'object') {
						objectNodes.push(node);
					}
					if (node.getChildren) node.getChildren().forEach(traverse);
				};
				traverse(root);
			});
			objectNodes.forEach(node => {
				const toolbarObj = toolbar.objects.find(obj => {
					if (obj.items?.length)
						return obj.items
							.map(item => item.value)
							.includes(node.getContent());
				});
				if (toolbarObj) toolbarObj.createInstance(node);
			});
		});
	}

	drawObjects() {
		const toolbar = this;
		if (toolbar.objects?.length) {
			toolbar.objects.forEach(object => {
				if (!object.invalid) object.draw();
			});
		}
	}

	fillTopToolbar() {
		this.modifiers
			.map(mod => mod.button)
			.forEach(button => {
				this.element.appendChild(button);
			});
		this.aligners
			.map(aligner => aligner.button)
			.forEach(button => {
				this.element.appendChild(button);
			});
		if (this.options.useRichText)
			this.element.appendChild(this.formattersDropdown.getEl());
		if (this.options.useFont)
			this.element.appendChild(this.fontsDropdown.getEl());
		if (this.options.useColor)
			this.element.appendChild(this.textColorPicker.getEl());
		if (this.options.useFontSize)
			this.element.appendChild(this.fontSizesDropdown.getEl());
		if (this.options.useLineHeight)
			this.element.appendChild(this.lineHeightsDropdown.getEl());
		if (this.options.useClearFormat)
			this.element.appendChild(this.clearFormattingButton);
		if (this.options.useLink) this.element.appendChild(this.linkButton);
		if (this.extraDropdowns.top.length) {
			this.extraDropdowns.top.forEach(dropdown => {
				this.element.appendChild(dropdown.getEl());
			});
		}
		this.drawObjects();
	}

	addElement(element, position = 'top') {
		const toolbar = this;
		switch (position) {
			default:
				if (position != 'top')
					console.warn(
						`The position ${position} is not supported yet for the addition of an element to the toolbar. Adding to the top instead.`
					);
				toolbar.element.appendChild(element);
				break;
		}
	}

	handleSelectionChange(selection) {
		this.currentSelection = selection;
		// if (this.options.useLink) this.closeLinkEditor();
		this.evaluateActiveButtons(selection);
	}

	evaluateActiveButtons(selection) {
		this.evaluateModifiers(selection);
		this.evaluateAligners(selection);
		if (this.options.useRichText) this.evaluateFormatters(selection);
		if (this.options.useColor) this.evaluateColor(selection);
		if (this.options.useFont) this.evaluateFont(selection);
		if (this.options.useFontSize) this.evaluateFontSize(selection);
		if (this.options.useLineHeight) this.evaluateLineHeight(selection);
		if (this.options.useLink) this.evaluateLink(selection);
		this.evaluateExtraDropdowns(selection);
	}

	evaluateModifiers(selection) {
		this.modifiers.forEach(modifier => {
			let isActive;
			if (this.options.useRichText && selection?.hasFormat) {
				isActive = selection.hasFormat(modifier.payload);
			} else if (
				typeof this.options.modifiersEval[modifier.payload] ===
				'function'
			) {
				isActive =
					this.options.modifiersEval[modifier.payload](toolbar);
			}

			this.setButtonActive(modifier.button, isActive || false);
		});
	}

	setButtonActive(button, isActive) {
		button.classList[isActive ? 'add' : 'remove'](
			`${this.className}__button--active`
		);
	}

	evaluateExtraDropdowns(selection) {
		const toolbar = this;
		for (const pos in toolbar.extraDropdowns) {
			if (toolbar.extraDropdowns.hasOwnProperty(pos)) {
				if (toolbar.extraDropdowns[pos].length) {
					toolbar.extraDropdowns[pos].forEach(dropdown => {
						dropdown.updateAllItemsSelection({
							textEditor: toolbar.textEditor,
							selection
						});
					});
				}
			}
		}
	}

	evaluateAligners(selection) {
		let selectionAlignment;
		if (this.options.useRichText && $isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			const element =
				anchorNode.getKey() === 'root'
					? anchorNode
					: anchorNode.getTopLevelElementOrThrow();
			const elementKey = element.getKey();
			const elementDOM = this.editor.getElementByKey(elementKey);
			if (elementDOM) {
				selectionAlignment = getComputedStyle(elementDOM)?.textAlign;
			}
		}

		this.aligners.forEach(aligner => {
			let isActive;
			if (selectionAlignment) {
				isActive = selectionAlignment === aligner.payload;
			} else if (
				typeof this.options.modifiersEval[aligner.name] === 'function'
			) {
				isActive = this.options.modifiersEval[aligner.name](toolbar);
			}

			this.setButtonActive(aligner.button, isActive || false);
		});
	}

	evaluateFormatters(selection) {
		let type;
		if ($isRangeSelection(selection)) {
			const anchorNode = selection.anchor.getNode();
			const element =
				anchorNode.getKey() === 'root'
					? anchorNode
					: anchorNode.getTopLevelElementOrThrow();
			const elementKey = element.getKey();
			const elementDOM = this.editor.getElementByKey(elementKey);
			if (elementDOM !== null) {
				if ($isListNode(element)) {
					const parentList = $getNearestNodeOfType(
						anchorNode,
						ListNode
					);
					type = parentList ? parentList.getTag() : element.getTag();
				} else {
					type = $isHeadingNode(element)
						? element.getTag()
						: element.getType();
				}
			}
		}
		const item = this.formatters.find(f => f.type === type)?.item;
		if (item)
			this.formattersDropdown.activateItem(
				this.formatters.find(f => f.type === type)?.item
			);
	}

	evaluateColor(selection) {
		if (this.options.useRichText && $isRangeSelection(selection)) {
			const el = this.editor.getElementByKey(
				selection.anchor.getNode().getKey()
			);
			if (el && getComputedStyle(el)?.color) {
				this.textColorPicker.changeColor(getComputedStyle(el).color);
			}
		} else if (!this.options.useRichText && this.plainTextState?.color) {
			this.textColorPicker.changeColor(this.plainTextState.color);
		}
	}

	evaluateFont(selection) {
		let font;
		if (this.options.useRichText && $isRangeSelection(selection)) {
			const el = this.editor.getElementByKey(
				selection.anchor.getNode().getKey()
			);
			if (el && getComputedStyle(el)?.fontFamily) {
				const fontValue = getComputedStyle(el).fontFamily;
				let fontFamily = unquoteString(fontValue);
				if (fontValue.includes(',')) {
					const fontsArray =
						fontFamily.split(', ') || fontFamily.split(',');
					fontFamily = fontsArray[0];
				}
				font = this.fonts.find(
					f => f.name === unquoteString(fontFamily)
				);
			}
		} else if (!this.options.useRichText && this.plainTextState?.font) {
			font = this.fonts.find(
				f => f.name === unquoteString(this.plainTextState.font)
			);
		}
		if (font?.item) this.fontsDropdown.activateItem(font.item);
	}

	evaluateFontSize(selection) {
		let size;
		if (this.options.useRichText && $isRangeSelection(selection)) {
			const el = this.editor.getElementByKey(
				selection.anchor.getNode().getKey()
			);
			if (el && getComputedStyle(el)?.fontSize) {
				size = this.fontSizes.find(
					fs => fs.name == getComputedStyle(el).fontSize
				);
			}
		} else if (!this.options.useRichText && this.plainTextState?.fontSize) {
			size = this.fontSizes.find(
				fs => fs.name === unquoteString(this.plainTextState.fontSize)
			);
		}
		if (size?.item) this.fontSizesDropdown.activateItem(size.item);
	}

	evaluateLineHeight(selection) {
		if ($isRangeSelection(selection)) {
			const el = this.editor.getElementByKey(
				selection.anchor.getNode().getKey()
			);
			if (el && getComputedStyle(el)?.lineHeight) {
				const font = this.lineHeights.find(
					lh =>
						lh.name == getComputedStyle(el).lineHeight ||
						parseFloat(lh.name).toFixed(2) ==
							parseFloat(
								parseFloat(
									getComputedStyle(el).lineHeight
								).toFixed(2) /
									parseFloat(
										getComputedStyle(el).fontSize
									).toFixed(2)
							).toFixed(2)
				);
				if (font) this.lineHeightsDropdown.activateItem(font.item);
			}
		}
	}

	evaluateLink(selection) {
		if ($isRangeSelection(selection)) {
			const node = selection.anchor.getNode();

			if (isLinkNode(node.getParent()) || isLinkNode(node)) {
				this.linkButton.classList.add(
					`${this.className}__button--active`
				);

				if (
					(this.linkEditor && isLinkNode(node.getParent())) ||
					isLinkNode(node)
				) {
					const link = isLinkNode(node) ? node : node.getParent();
					if (link.getOptions().editorState) {
						this.UrlEditor.setState(link.getOptions().editorState);
					} else {
						this.UrlEditor.setHtml(link.getOptions().url);
					}
				}
			} else {
				if (this.linkEditor) this.UrlEditor.clear();
				this.linkButton.classList.remove(
					`${this.className}__button--active`
				);
			}
		}
	}

	clearFormatting() {
		if (this.options.useRichText) {
			clearFormattingPlugin(this.editor);
		} else {
			this.plainTextState = { ...this.options.plainTextOptions };
			if (
				typeof this.options.PlainTextCallbacks?.clearFormatting ===
				'function'
			) {
				this.options.PlainTextCallbacks.clearFormatting(this);
			}
		}
	}

	closeLinkEditor() {
		this.linkPopup.popup.close();
	}

	invalidateUrl() {
		console.error('Invalid URL');
	}

	handleLinkAdd(urlEditorText, urlEditorState) {
		const toolbar = this;
		// if (!validateUrl(urlEditorText)) {
		// 	toolbar.invalidateUrl();
		// 	return;
		// }

		toolbar.editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
			url: urlEditorText, // sanitizeUrl(urlEditorText)
			editorState: urlEditorState
		});

		if (toolbar.options.boldLinks)
			toolbar.ifSelectionNotBold(
				toolbar.editor.dispatchCommand.bind(
					toolbar.editor,
					FORMAT_TEXT_COMMAND,
					'bold'
				)
			);

		toolbar.closeLinkEditor();
	}

	ifSelectionNotBold(cb) {
		if (typeof cb == 'function')
			return this.editor.update(() => {
				const selection = $getSelection();
				if ($isRangeSelection(selection)) {
					return !$getSelection().hasFormat('bold') && cb();
				} else if ($isNodeSelection(selection)) {
					selection.getNodes().forEach(node => {
						// TODO: add format properties and methods to Object node and then -> return !node.hasFormat('bold') && cb();
					});

					return false;
				}
			});
	}

	handleLinkRemove(inputValue, e) {
		this.editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);

		this.closeLinkEditor();
	}

	getEl() {
		return this.element;
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}

	destroy() {
		// TODO: add more destroy logic
		if (this.linkPopup instanceof Popup) {
			this.linkPopup.popup.destroy();
		}
		this.textColorPicker.destroy();
	}
}
