import { merge } from 'datatalks-utils';
import { Button } from 'datatalks-ui';

export default (obj, options, customTrait = {}) => {
	const localize = obj.component.localize;
	const defaults = {
		className: 'items-list-wrapper',
		button: {
			display: true,
			onClick: null,
			content: `+ ${localize('Add new item')}`,
			appendTo: null,
			isOutline: true,
			extendedClasses: 'eb-w-full eb-mt-4',
			dataAction: 'create',
			isDisabled: false
		},
		componentProperties: {
			itemsList: 'linksList'
		},
		componentMethods: {
			getItemsList: null
		}
	};

	options = merge(defaults, options);

	const itemsListWrapper = document.createElement('div');
	itemsListWrapper.classList.add(options.className);

	let itemsList;

	if (obj.component.get(options.componentProperties.itemsList)?.length) {
		itemsList = obj.component.get(options.componentProperties.itemsList);
	} else if (
		options.componentMethods.getItemsList &&
		typeof obj.component[options.componentMethods.getItemsList] ===
			'function'
	) {
		itemsList = obj.component[options.componentMethods.getItemsList].call(
			obj.component
		);
	}

	if (itemsList) {
		obj.trait.setItems(itemsList, customTrait);

		itemsListWrapper.append(obj.trait.itemsListElement);
	}

	const addItemBtn = new Button({
		appendTo: options.button.appendTo || itemsListWrapper,
		content: options.button.content,
		isOutline: options.button.isOutline,
		extendedClasses: options.button.extendedClasses,
		isDisabled: options.button.isDisabled,
		onClick: options.button.onClick
	}).getEl();
	addItemBtn.setAttribute('eb-data-action', options.button.dataAction);

	return itemsListWrapper;
};
