import { TextEditor } from 'datatalks-ui';
import { merge } from 'datatalks-utils';

/**
 * UrlEditor class extends the TextEditor class to provide a specialized editor for URLs.
 *
 * @class UrlEditor
 * @extends {TextEditor}
 *
 * @param {Object} options - Configuration options for the UrlEditor.
 * @param {boolean} [options.useRichText=true] - Flag to enable or disable rich text editing.
 * @param {Object} [options.toolbarOptions] - Configuration for the toolbar options.
 * @param {boolean} [options.toolbarOptions.useRichText=true] - Flag to enable or disable rich text in the toolbar.
 * @param {Object} [options.toolbarOptions.useModifiers] - Configuration for the text modifiers in the toolbar.
 * @param {boolean} [options.toolbarOptions.useModifiers.bold=false] - Flag to enable or disable bold text.
 * @param {boolean} [options.toolbarOptions.useModifiers.italic=false] - Flag to enable or disable italic text.
 * @param {boolean} [options.toolbarOptions.useModifiers.strikethrough=false] - Flag to enable or disable strikethrough text.
 * @param {boolean} [options.toolbarOptions.useModifiers.underline=false] - Flag to enable or disable underline text.
 * @param {boolean} [options.toolbarOptions.useModifiers.superscript=false] - Flag to enable or disable superscript text.
 * @param {boolean} [options.toolbarOptions.useModifiers.code=false] - Flag to enable or disable code text.
 * @param {boolean} [options.toolbarOptions.useModifiers.alignLeft=false] - Flag to enable or disable left alignment.
 * @param {boolean} [options.toolbarOptions.useModifiers.alignCenter=false] - Flag to enable or disable center alignment.
 * @param {boolean} [options.toolbarOptions.useModifiers.alignRight=false] - Flag to enable or disable right alignment.
 * @param {boolean} [options.toolbarOptions.useModifiers.alignJustify=false] - Flag to enable or disable justify alignment.
 */
export default class UrlEditor extends TextEditor {
	/**
	 * Constructs a new instance of the UrlEditor class.
	 *
	 * @constructor
	 * @param {Object} options - Configuration options for the UrlEditor.
	 */
	constructor(options) {
		const defaults = {
			parentEditor: null,
			useRichText: false,
			toolbarOptions: {
				useRichText: false,
				useModifiers: {
					bold: false,
					italic: false,
					strikethrough: false,
					underline: false,
					superscript: false,
					code: false,
					alignLeft: false,
					alignCenter: false,
					alignRight: false,
					alignJustify: false
				},
				useLink: false,
				useLineHeight: false,
				useFontSize: false,
				useColor: false,
				useFont: false,
				useClearFormat: false
			},
			logTreeView: false
		};
		options = merge(defaults, options);
		super(options);
		this.parentEditor = options.parentEditor;
	}
}
