/* eslint-disable require-jsdoc */
import { DecoratorNode } from 'lexical';
import { EventEmitter, merge } from 'datatalks-utils';

export class ObjectNode extends DecoratorNode {
	constructor(options, key) {
		super(key);
		this.defaults = {
			content: 'Default content',
			style: '',
			startActive: false
		};
		this.__options = merge(this.defaults, options);
		this.state = {
			isActive: this.__options.startActive || false
		};
		this.__content = options.content;
		this.__style = options.style;
		this.eventEmitter = new EventEmitter();
	}

	static getType() {
		return 'object';
	}

	static clone(node) {
		const clone = new ObjectNode(
			{
				...node.__options,
				content: node.getContent() || node.__options.content,
				style: node.getStyle() || node.__style || null,
				startActive: node.isActive()
			},
			node.__key
		);
		const { onClone } = node.__options;
		if (typeof onClone === 'function') {
			onClone(clone);
		}
		return clone;
	}

	shouldUpdate(options, newOptions) {
		return options.content !== newOptions.content;
	}

	updateOptions() {
		const self = this.getWritable();
		this.setContent(self.__options.content);
	}

	setOptions(options) {
		const self = this.getWritable();
		self.__options = options;
		if (this.shouldUpdate(self.__options, options)) {
			this.updateOptions();
		}
	}

	getOptions() {
		const self = this.getLatest();
		return self.__options;
	}

	addOptions(options) {
		const self = this.getWritable();
		self.__options = merge(self.__options, options);
		if (this.shouldUpdate(self.__options, options)) {
			this.updateOptions();
		}
	}

	setStyle(styleString) {
		const self = this.getWritable();
		self.__style = styleString;
	}

	getStyle() {
		const self = this.getLatest();
		return self.__style || '';
	}

	setContent(content) {
		const self = this.getWritable();
		self.__content = content;
	} // TODO: same for setActive instead of activate/deactivate

	getContent() {
		const self = this.getLatest();
		return self.__content;
	}

	createDOM(config) {
		const domElement = document.createElement('span');
		domElement.classList.add('eb-prosetyper-object-node');
		domElement.addEventListener('click', this.handleClick.bind(this));
		domElement.innerHTML = this.getContent();
		if (this.__style) domElement.style = this.__style;
		return domElement;
	}

	decorate() {
		const div = document.createElement('div');
		div.textContent = this.getContent(); // Display inner content
		return div;
	}

	updateDOM(prevNode, dom) {
		return (
			prevNode.getContent() !== dom.innerHTML ||
			prevNode.__style !== dom.getAttribute('style')
		);
	}

	handleClick(event) {
		const { onClick } = this.__options;
		this.toggle(event.target);
		if (onClick) {
			onClick(event);
		}
		this.eventEmitter.emit('click', event);
	}

	isActive() {
		return this.state.isActive;
	}

	toggle(dom) {
		if (this.isActive()) {
			this.deactivate(dom);
		} else {
			this.activate(dom);
		}
	}

	activate(dom) {
		this.state.isActive = true;
		if (dom?.classList) {
			dom.classList.add('eb-prosetyper-object-node--active');
		} else {
			console.warn(
				`ObjectNode's 'activate' method called without target dom`
			);
		}
		this.eventEmitter.emit('activate');
	}

	deactivate(dom) {
		this.state.isActive = false;
		if (dom?.classList) {
			dom.classList.remove('eb-prosetyper-object-node--active');
		} else {
			console.warn(
				`ObjectNode's 'deactivate' method called without target dom`
			);
		}
		this.eventEmitter.emit('deactivate');
	}

	exportDOM() {
		const element = document.createElement('span');
		element.innerHTML = this.getContent();
		if (this.__style) element.setAttribute('style', this.__style);
		return { element };
	}

	getTextContent() {
		return this.getContent();
	}

	static importDOM() {
		return {
			div: node => ({
				conversion: convertObjectNode,
				priority: 0
			})
		};
	}

	static importJSON(json) {
		const { options, content, style } = json;
		options.content = content || options.content;
		options.style = style || options.style;
		return new ObjectNode(options);
	}

	exportJSON() {
		return {
			type: 'object',
			version: 1,
			options: this.__options,
			content: this.__content,
			style: this.__style
		};
	}

	on(event, callback) {
		this.eventEmitter.on(event, callback);
	}

	off(event, callback) {
		this.eventEmitter.off(event, callback);
	}
}

function convertObjectNode(domNode) {
	const node = $createObjectNode();
	return { node };
}

export function $createObjectNode(options = {}) {
	return new ObjectNode(options);
}
