import { merge } from 'datatalks-utils';
import { getUnit, isElement } from 'datatalks-utils';
import { InputNumber, SectionItem } from 'datatalks-ui';
import { Dropdown } from 'email-builder-components';

/* eslint-disable require-jsdoc */
export default (obj, options) => {
	const localize = obj.component.localize;
	const defaults = {
		elementName: null
	};

	options = merge(defaults, options);

	if (!options.elementName) {
		console.error(
			'You must pass an element name to the letter spacing container prop'
		);
		return null;
	}

	const props = {
		normalLetterSpacing: options.elementName + 'NormalLetterSpacing',
		defaultLetterSpacingLength:
			options.elementName + 'DefaultLetterSpacingLength'
	};

	let letterSpacingInput;

	function evaluateLetterSpacingContainer() {
		const hasInput = !obj.component.get(props.normalLetterSpacing);
		if (hasInput) {
			letterSpacingInput = new InputNumber({
				defaultValue: obj.component.get(
					props.defaultLetterSpacingLength
				),
				unit:
					getUnit(
						obj.component.get(props.defaultLetterSpacingLength)
					) || 'px',
				increment: 0.1,
				changeableUnit: true,
				onChange: (value, unit, inputValue) => {
					obj.component.setLetterSpacing.call(
						obj.component,
						inputValue
					);
				}
			});
			obj.component.setLetterSpacing.call(
				obj.component,
				letterSpacingInput.getInputValue()
			);
			letterSpacingContainer.append(letterSpacingInput.getEl());
		} else if (
			letterSpacingInput &&
			letterSpacingContainer.contains(letterSpacingInput.getEl())
		) {
			letterSpacingContainer.removeChild(letterSpacingInput.getEl());
			obj.component.setLetterSpacing.call(obj.component, 'normal');
		}
	}

	const letterSpacingContainer = new SectionItem({
		label:
			localize('traits.styles.properties.letterSpacing', true) ||
			localize('Letter Spacing'),
		content: new Dropdown({
			items: [
				{
					content:
						localize('misc.normal', true) || localize('Normal'),
					value: true,
					active: obj.component.get(props.normalLetterSpacing)
				},
				{
					content: localize('Set length'),
					value: false,
					active: !obj.component.get(props.normalLetterSpacing)
				}
			],
			onChange: (dropdown, activeItem) => {
				obj.component.setNormalLetterSpacing.call(
					obj.component,
					activeItem.getValue()
				);
				if (isElement(letterSpacingContainer))
					evaluateLetterSpacingContainer();
			}
		}).getEl()
	}).getEl();

	evaluateLetterSpacingContainer();

	return letterSpacingContainer;
};
