/* eslint-disable require-jsdoc */
import { merge, arrayUnshift } from 'datatalks-utils';
import { SectionItem } from 'datatalks-ui';
import { Dropdown, ColorPickerInput } from 'email-builder-components';
import fontsDropdown from '../../traits/commonAccordions/style/common/_fonts';

export default (options, emailBuilder) => {
	const defaults = {
		fontFamily: {
			active: true,
			label:
				emailBuilder.getTranslation(
					'traits.styles.properties.fontFamily',
					true
				) || emailBuilder.getTranslation('Font'),
			value: null,
			onChange: null
		},
		fontFallback: {
			active: true,
			label:
				emailBuilder.getTranslation(
					'traits.styles.properties.fontFallback',
					true
				) || emailBuilder.getTranslation('Font Fallback'),
			value: null,
			onChange: null,
			isHidden: false
		},
		fontSize: {
			active: true,
			label:
				emailBuilder.getTranslation(
					'traits.styles.properties.fontSize',
					true
				) || emailBuilder.getTranslation('Size'),
			value: null,
			onChange: null
		},
		fontWeight: {
			active: true,
			label:
				emailBuilder.getTranslation(
					'traits.styles.properties.fontWeight',
					true
				) || emailBuilder.getTranslation('Weight'),
			value: null,
			onChange: null
		},
		color: {
			active: true,
			label:
				emailBuilder.getTranslation(
					'traits.styles.properties.color',
					true
				) || emailBuilder.getTranslation('Color'),
			value: null,
			emptyValue: null,
			emptyValueLabel: null,
			onChange: null,
			onReset: null
		},
		lineHeight: {
			active: true,
			label:
				emailBuilder.getTranslation(
					'traits.styles.properties.lineHeight',
					true
				) || emailBuilder.getTranslation('Line height'),
			value: null,
			onChange: null
		},
		letterSpacing: {
			active: true,
			label:
				emailBuilder.getTranslation(
					'traits.styles.properties.letterSpacing',
					true
				) || emailBuilder.getTranslation('Letter Spacing'),
			value: null,
			onChange: null
		},
		textAlign: {
			active: true,
			label:
				emailBuilder.getTranslation(
					'traits.styles.properties.textAlignment',
					true
				) || emailBuilder.getTranslation('Text alignment'),
			value: null,
			onChange: null
		},
		textDecoration: {
			active: true,
			label:
				emailBuilder.getTranslation(
					'traits.styles.properties.textDecoration',
					true
				) || emailBuilder.getTranslation('Text decoration'),
			value: null,
			onChange: null
		},
		fontStyle: {
			active: true,
			label:
				emailBuilder.getTranslation(
					'traits.styles.properties.fontStyle',
					true
				) || emailBuilder.getTranslation('Font style'),
			value: null,
			onChange: null
		}
	};

	options = merge(defaults, options);

	const fontFallbackSection = new SectionItem({
		label: options.fontFallback.label,
		isHidden: options.fontFallback.isHidden,
		content: fontsDropdown({
			value: options.fontFallback.value,
			onChange: options.fontFallback.onChange,
			onReset: options.fontFallback.onReset,
			useWebFonts: false
		}).getEl()
	});

	const fontFamilySection = new SectionItem({
		label: options.fontFamily.label,
		content: fontsDropdown({
			value: options.fontFamily.value,
			onChange: value => {
				options.fontFamily.onChange(value);
				fontFallbackSection.show();
			},
			onReset:
				options.fontFamily.onReset &&
				(() => {
					options.fontFamily.onReset();
					fontFallbackSection.hide();
				})
		}).getEl()
	});

	const fontSizeSection = new SectionItem({
		label: options.fontSize.label,
		content: new Dropdown({
			items: emailBuilder.getAvailableFontSizes().map(size => ({
				value: size,
				content: size,
				active: options.fontSize.value === size
			})),
			onChange: (dropdown, activeItem) => {
				if (typeof options.fontSize.onChange === 'function')
					options.fontSize.onChange(activeItem.getValue());
			}
		}).getEl()
	});

	const colorSection = new SectionItem({
		label: options.color.label,
		content: new ColorPickerInput({
			color: options.color.value,
			onChange: (cpi, ev) => {
				if (typeof options.color.onChange === 'function')
					options.color.onChange(cpi.getColor());
			},
			emptyColor: options.color.emptyValue,
			emptyColorLabel: options.color.emptyValueLabel,
			onReset: (cpi, emptyColor) => {
				if (typeof options.color.onReset === 'function')
					options.color.onReset(emptyColor);
			}
		}).getEl()
	});

	const lineHeightSection = new SectionItem({
		label: options.lineHeight.label,
		content: new Dropdown({
			items: arrayUnshift(
				emailBuilder.getAvailableLineHeights().map(size => ({
					value: size,
					content: size,
					active: options.lineHeight.value == size
				})),
				{
					value: null,
					content: emailBuilder.getTranslation('Same as email'),
					active: options.lineHeight.value == null,
					isDefault: true
				}
			),
			onChange: (dropdown, activeItem) => {
				if (typeof options.lineHeight.onChange === 'function')
					options.lineHeight.onChange(activeItem.getValue());
			},
			resettable: true,
			onReset: () => {
				if (typeof options.lineHeight.onReset === 'function')
					options.lineHeight.onReset();
			}
		}).getEl()
	});

	return [
		fontFamilySection.getEl(),
		fontFallbackSection.getEl(),
		fontSizeSection.getEl(),
		colorSection.getEl(),
		lineHeightSection.getEl()
	];
};
