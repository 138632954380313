/* eslint-disable require-jsdoc */
import {
	Button,
	SectionItem,
	Accordion,
	ToggleableSection,
	LinkedInputs,
	InputNumber
} from 'datatalks-ui';
import { ColorPickerInput } from 'email-builder-components';
import {
	addClassesString,
	setContent,
	merge,
	getUnit,
	allEqual,
	isValidColor
} from 'datatalks-utils';
import { capitalize, kebabCase, startCase } from 'lodash-es';

import sliderInput from '../../traits/commonAccordions/style/common/_sliderOrInput';
import textStylesAccordion from './_textStylesAccordion';
import fontsDropdown from '../../traits/commonAccordions/style/common/_fonts';
import lineHeightDropdown from '../../traits/commonAccordions/style/common/_lineHeight';
import borderAccordion from '../../traits/commonAccordions/style/_border';
import buttonsAccordion from './components/_buttonsAccordion';

export default (commandOptions, emailBuilder) => {
	const localize = emailBuilder.translationFramework.getTranslation.bind(
		emailBuilder.translationFramework
	);
	const defaults = {
		properties: {
			advancedOptions: {
				isMobileOptimized: {
					active: true
				}
			},
			dimensions: {
				email: {
					active: true
				}
			},
			background: {
				email: {
					active: true
				},
				outer: {
					active: true
				},
				components: {
					active: true
				}
			},
			border: {
				borderWidth: {
					active: true
				},
				borderStyle: {
					active: true
				},
				borderColor: {
					active: true
				},
				borderRadius: {
					active: true
				}
			},
			text: {
				wrapper: {
					active: true,
					color: {
						active: true
					},
					size: {
						active: true
					},
					lineHeight: {
						active: true
					},
					fontFamily: {
						active: true
					},
					fontFallback: {
						active: true
					}
				},
				paragraph: {
					active: true,
					color: {
						active: true
					},
					size: {
						active: true
					},
					lineHeight: {
						active: true
					},
					fontFamily: {
						active: true
					},
					fontFallback: {
						active: true
					}
				},
				h1: {
					active: true,
					color: {
						active: true
					},
					size: {
						active: true
					},
					lineHeight: {
						active: true
					},
					fontFamily: {
						active: true
					},
					fontFallback: {
						active: true
					}
				},
				h2: {
					active: true,
					color: {
						active: true
					},
					size: {
						active: true
					},
					lineHeight: {
						active: true
					},
					fontFamily: {
						active: true
					},
					fontFallback: {
						active: true
					}
				},
				h3: {
					active: true,
					color: {
						active: true
					},
					size: {
						active: true
					},
					lineHeight: {
						active: true
					},
					fontFamily: {
						active: true
					},
					fontFallback: {
						active: true
					}
				},
				h4: {
					active: true,
					color: {
						active: true
					},
					size: {
						active: true
					},
					lineHeight: {
						active: true
					},
					fontFamily: {
						active: true
					},
					fontFallback: {
						active: true
					}
				},
				h5: {
					active: true,
					color: {
						active: true
					},
					size: {
						active: true
					},
					lineHeight: {
						active: true
					},
					fontFamily: {
						active: true
					},
					fontFallback: {
						active: true
					}
				},
				h6: {
					active: true,
					color: {
						active: true
					},
					size: {
						active: true
					},
					lineHeight: {
						active: true
					},
					fontFamily: {
						active: true
					},
					fontFallback: {
						active: true
					}
				}
			},
			buttons: {
				backgroundColor: {
					active: true
				},
				color: {
					active: true
				},
				fontFamily: {
					active: true
				},
				height: {
					active: true
				},
				lineHeight: {
					active: true
				},
				innerMargin: {
					active: true
				},
				borderRadius: {
					active: true
				}
			},
			links: {
				color: {
					active: true
				},
				underline: {
					active: true
				},
				underlineColor: {
					active: true
				}
			}
		}
	};

	commandOptions = merge(defaults, commandOptions);

	let editPanel = null;

	function shouldSectionRender(sectionName) {
		return Object.values(commandOptions.properties[sectionName]).some(
			prop => prop.active
		);
	}

	function drawEditPanel(editor) {
		const className = 'general-style-pn';
		const content = addClassesString(
			document.createElement('div'),
			`${className}__content`
		);
		const footer = addClassesString(
			document.createElement('div'),
			`${className}__footer`
		);

		let advancedOptionsSection;
		let dimensionsSection;
		let backgroundSection;
		let borderSection;
		let buttonsSection;
		let linksSection;
		let textStylesSection;

		if (
			commandOptions.properties.advancedOptions.isMobileOptimized
				.active &&
			shouldSectionRender('advancedOptions')
		) {
			advancedOptionsSection = new Accordion({
				title:
					localize('traits.styles.sections.renderingOptions', true) ||
					localize('Rendering Options'),
				accordionColor: 'grey',
				accordionType: 'extend',
				content: new ToggleableSection({
					label: setContent(document.createElement('div'), [
						localize('features.mobileOptimization.label', true) ||
							localize('Mobile Optimization'),
						setContent(
							addClassesString(
								document.createElement('span'),
								'eb-pill eb-ml-2'
							),
							['BETA']
						)
					]),
					startOpen:
						emailBuilder.getAdvancedOption('isMobileOptimized'),
					onToggle: isActive => {
						emailBuilder.setAdvancedOption(
							'isMobileOptimized',
							isActive
						);
					},
					content: `<p>${
						localize(
							'features.mobileOptimization.description',
							true
						) ||
						localize(
							'This feature enhances email appearance on mobile devices, although the final display may vary across different clients like Gmail, Outlook, as they use different rendering engines. Currently, it optimizes: Header, Footer and Horizontal List, with additional components to be improved in the near future.'
						)
					}</p>`,
					toggleableContent: true
				}).getEl()
			}).getEl();
		}

		if (
			commandOptions.properties.dimensions.email.active &&
			shouldSectionRender('dimensions')
		)
			dimensionsSection = new Accordion({
				title:
					localize('traits.styles.sections.dimensions', true) ||
					localize('Dimensions'),
				accordionColor: 'grey',
				accordionType: 'extend',
				content: sliderInput({
					value: emailBuilder.getStyleProp('width'),
					onChange: value => {
						editor.runCommand('change-general-style-prop', {
							changedProp: 'width',
							oldValue: emailBuilder.getStyleProp('width'),
							newValue: value
						});
						emailBuilder.setStyleProp('width', value);
						emailBuilder.applyGeneralStyles('width');
					},
					sliderOptions: {},
					InputNumberOptions: {},
					max: 1920,
					min: 375,
					unit: 'px',
					showInputByDefault: false,
					label:
						localize('traits.styles.labels.emailWidth', true) ||
						localize('Email width'),
					showInputLabel: localize('Specify value')
				})
			}).getEl();

		if (shouldSectionRender('background'))
			backgroundSection = new Accordion({
				title:
					localize('traits.styles.sections.background', true) ||
					localize('Background'),
				accordionColor: 'grey',
				accordionType: 'extend',
				content: [
					commandOptions.properties.background.email.active &&
						new SectionItem({
							label:
								emailBuilder.getTranslation(
									'traits.styles.labels.backgroundColor'
								) || 'Background color',
							content: new ColorPickerInput({
								color: emailBuilder.getStyleProp('background')
									.email,
								onChange: (cpi, ev) => {
									editor.runCommand(
										'change-general-style-prop',
										{
											changedProp: 'backgroundColor',
											oldValue:
												emailBuilder.getStyleProp(
													'background'
												).email,
											newValue: cpi.getColor()
										}
									);
									emailBuilder.setStyleProp('background', {
										...emailBuilder.getStyleProp(
											'background'
										),
										email: cpi.getColor()
									});
									emailBuilder.applyGeneralStyles(
										'background'
									);
								}
							}).getEl()
						}).getEl(),
					commandOptions.properties.background.outer.active &&
						new ToggleableSection({
							label:
								emailBuilder.getTranslation(
									'traits.styles.labels.outerBackgroundColor'
								) || 'Outer background color',
							content: new ColorPickerInput({
								color: emailBuilder.styles.background.outer,
								onChange: (cpi, ev) => {
									emailBuilder.setOuterBackground(
										cpi.getColor()
									);
								},
								emptyColor:
									emailBuilder.getOptions().config.styles
										.background.outer,
								emptyColorLabel:
									emailBuilder.getOptions().config.styles
										.background.outer
							}).getEl(),
							toggleableContent: true,
							startOpen: emailBuilder.layout.useOuterBackground,
							onToggle: isActive =>
								emailBuilder.setUseOuterBackground(isActive)
						}).getEl(),
					commandOptions.properties.background.components.active &&
						new SectionItem({
							label:
								emailBuilder.getTranslation(
									'traits.styles.labels.componentsBackgroundColor'
								) || 'Components background color',
							content: new ColorPickerInput({
								color: emailBuilder.styles.background
									.components,
								onChange: (cpi, ev) => {
									emailBuilder.setStyleProp('background', {
										...emailBuilder.getStyleProp(
											'background'
										),
										components: cpi.getColor()
									});
									editor.setStyleRules(
										'component',
										{
											['background-color']: cpi.getColor()
										},
										{ addStyles: true }
									);
								},
								emptyColor: 'transparent'
							}).getEl()
						}).getEl()
				]
			}).getEl();

		if (shouldSectionRender('border'))
			borderSection = borderAccordion({
				accordion: {
					title:
						localize('traits.styles.sections.border', true) ||
						localize('Border')
				},
				traitsVisibility: {
					borderWidth:
						commandOptions.properties.border.borderWidth.active,
					borderStyle:
						commandOptions.properties.border.borderStyle.active,
					borderColor:
						commandOptions.properties.border.borderColor.active,
					borderRadius:
						commandOptions.properties.border.borderRadius.active
				},
				borderWidth: {
					value: emailBuilder.styles.borderWidth,
					onChange: width => {
						emailBuilder.setStyleProp('borderWidth', width);
						editor.setStyleRules(
							'component',
							{
								['border-width']: width
							},
							{ addStyles: true }
						);
					}
				},
				borderStyle: {
					value: emailBuilder.styles.borderStyle,
					onChange: style => {
						emailBuilder.setStyleProp('borderStyle', style);
						editor.setStyleRules(
							'component',
							{
								['border-style']: style
							},
							{ addStyles: true }
						);
					}
				},
				borderColor: {
					value: emailBuilder.styles.borderColor,
					onChange: color => {
						emailBuilder.setStyleProp('borderColor', color);
						editor.setStyleRules(
							'component',
							{
								['border-color']: color
							},
							{ addStyles: true }
						);
					}
				},
				borderRadius: {
					corners: {
						topLeft: {
							value: emailBuilder.styles.borderRadius.topLeft,
							onChange: value => {
								emailBuilder.setStyleProp('borderRadius', {
									...emailBuilder.getStyleProp(
										'borderRadius'
									),
									topLeft: value
								});
								editor.setStyleRules(
									'component',
									{
										['border-top-left-radius']: value
									},
									{ addStyles: true }
								);
							}
						},
						topRight: {
							value: emailBuilder.styles.borderRadius.topRight,
							onChange: value => {
								emailBuilder.setStyleProp('borderRadius', {
									...emailBuilder.getStyleProp(
										'borderRadius'
									),
									topRight: value
								});
								editor.setStyleRules(
									'component',
									{
										['border-top-right-radius']: value
									},
									{ addStyles: true }
								);
							}
						},
						bottomRight: {
							value: emailBuilder.styles.borderRadius.bottomRight,
							onChange: value => {
								emailBuilder.setStyleProp('borderRadius', {
									...emailBuilder.getStyleProp(
										'borderRadius'
									),
									bottomRight: value
								});
								editor.setStyleRules(
									'component',
									{
										['border-bottom-right-radius']: value
									},
									{ addStyles: true }
								);
							}
						},
						bottomLeft: {
							value: emailBuilder.styles.borderRadius.bottomLeft,
							onChange: value => {
								emailBuilder.setStyleProp('borderRadius', {
									...emailBuilder.getStyleProp(
										'borderRadius'
									),
									bottomLeft: value
								});
								editor.setStyleRules(
									'component',
									{
										['border-bottom-left-radius']: value
									},
									{ addStyles: true }
								);
							}
						}
					}
				},
				showBorderRadius: emailBuilder.getStyleProp('useBorderRadius'),
				onBorderRadiusToggle: isActive => {
					emailBuilder.setStyleProp('useBorderRadius', isActive);
					if (!isActive) {
						editor.setStyleRules(
							'component',
							{
								['border-top-left-radius']: '0px',
								['border-top-right-radius']: '0px',
								['border-bottom-right-radius']: '0px',
								['border-bottom-left-radius']: '0px'
							},
							{ addStyles: true }
						);
					} else {
						editor.setStyleRules(
							'component',
							{
								['border-top-left-radius']:
									emailBuilder.getStyleProp('borderRadius')
										?.topLeft,
								['border-top-right-radius']:
									emailBuilder.getStyleProp('borderRadius')
										?.topRight,
								['border-bottom-right-radius']:
									emailBuilder.getStyleProp('borderRadius')
										?.bottomRight,
								['border-bottom-left-radius']:
									emailBuilder.getStyleProp('borderRadius')
										?.bottomLeft
							},
							{ addStyles: true }
						);
					}
				}
			}).getEl();

		if (shouldSectionRender('buttons'))
			buttonsSection = buttonsAccordion({}, emailBuilder).getEl();

		if (shouldSectionRender('links'))
			linksSection = new Accordion({
				title:
					localize('traits.styles.sections.linksStyles', true) ||
					localize('Links'),
				accordionColor: 'grey',
				accordionType: 'extend',
				content: [
					commandOptions.properties.links.color.active &&
						new SectionItem({
							label: localize("Links' color"),
							content: new ColorPickerInput({
								color: emailBuilder.getStyleProp('fontColors')
									.link,
								emptyColor: '#456cc0',
								emptyColorLabel: "Default link's color",
								onChange: (cpi, ev) => {
									editor.runCommand(
										'change-general-style-prop',
										{
											changedProp: 'linksColor',
											oldValue:
												emailBuilder.getStyleProp(
													'fontColors'
												).link,
											newValue: cpi.getColor()
										}
									);
									emailBuilder.setStyleProp('fontColors', {
										...emailBuilder.getStyleProp(
											'fontColors'
										),
										link: cpi.getColor()
									});
									emailBuilder.applyGeneralStyles(
										'fontColors'
									);
								}
							}).getEl()
						}).getEl(),
					commandOptions.properties.links.underline.active &&
						new ToggleableSection({
							label:
								emailBuilder.getTranslation(
									'traits.styles.labels.useUnderline'
								) || 'Use underline',
							startOpen:
								emailBuilder.getStyleProp('links').underline,
							toggleableContent: true,
							content: new SectionItem({
								label: localize("Link's underline color"),
								content:
									commandOptions.properties.links
										.underlineColor.active &&
									new ColorPickerInput({
										color:
											isValidColor(
												emailBuilder.getStyleProp(
													'links'
												).underlineColor
											) || null,
										emptyColor: () =>
											emailBuilder.getStyleProp(
												'fontColors'
											).link,
										emptyColorLabel: "Links' text color",
										onChange: (cpi, ev) => {
											emailBuilder.setStyleProp('links', {
												...emailBuilder.getStyleProp(
													'links'
												),
												underlineColor: cpi.getColor()
											});
											emailBuilder.applyGeneralStyles(
												'links'
											);
										}
									}).getEl()
							}).getEl(),
							onToggle: isActive => {
								emailBuilder.setStyleProp('links', {
									...emailBuilder.getStyleProp('links'),
									underline: isActive
								});
								emailBuilder.applyGeneralStyles('links');
							}
						}).getEl()
				]
			}).getEl();

		if (shouldSectionRender('text'))
			textStylesSection = textStylesAccordion(
				commandOptions.properties.text,
				emailBuilder
			).getEl();

		setContent(
			content,
			[
				dimensionsSection,
				backgroundSection,
				borderSection,
				textStylesSection,
				buttonsSection,
				linksSection,
				advancedOptionsSection
			].filter(section => !!section)
		);

		const resetBtn = new Button({
			content: localize('Reset general styles'),
			onClick: () => {
				editor.setBaseTheme();
				editor.runCommand('change-general-style-prop', {
					changedProp: 'all'
				});
				drawEditPanel(editor, false);
			},
			isOutline: true,
			buttonVariant: 'danger'
		}).getEl();
		footer.appendChild(resetBtn);

		const editMenuDiv = addClassesString(
			setContent(document.createElement('div'), [content, footer]),
			className
		);

		const panels = editor.Panels.getPanel('views-container');
		if (editPanel) {
			editPanel.remove();
		}
		panels
			.set('appendContent', editMenuDiv)
			.trigger('change:appendContent');

		editPanel = editMenuDiv;
	}

	return {
		run: function (editor) {
			if (editPanel == null) {
				drawEditPanel(editor);
			}
			editPanel.removeAttribute('eb-hidden');
		},
		stop: function (editor) {
			if (editPanel != null) {
				editPanel.setAttribute('eb-hidden', true);
			}
		}
	};
};
