import { ToggleButton, Accordion } from 'datatalks-ui';
import { TextEditor } from 'email-builder-components';
import { addClassesString, setContent } from 'datatalks-utils';
import { merge } from 'datatalks-utils';
import {
	textEditorCommonOptions,
	textEditorUpdateColorsOnGeneralChange
} from '../../../../common/functions/_textEditorCommonActions';

export default (obj, options = {}, customTrait = {}) => {
	const editor = obj.component.getEditor();
	const defaults = {
		hasTextToggler: true,
		toggleBtnClickTogglesAccordion: false,
		componentProperty: 'textContent',
		displayProperty: 'displayText',
		accordion: {
			title: 'Text',
			color: 'grey',
			type: 'extend'
		},
		textEditorProp: 'textEditor',
		editorStateProp: 'editorState'
	};

	options = merge(defaults, options);

	const textAccordionContent = document.createElement('div');

	if (!customTrait[options.textEditorProp]) {
		customTrait[options.textEditorProp] = new TextEditor({
			initialContent: obj.component.get(options.componentProperty),
			initialState: obj.component.get(options.editorStateProp),
			onChange: (html, editorState) => {
				obj.component.setAndRerender.call(
					obj.component,
					options.componentProperty,
					html
				);
				obj.component.set(options.editorStateProp, editorState);
			},
			...textEditorCommonOptions(editor)
		});

		textEditorUpdateColorsOnGeneralChange(
			editor,
			customTrait[options.textEditorProp]
		);
	}

	textAccordionContent.append(customTrait[options.textEditorProp].getEl());

	const title = options.hasTextToggler
		? addClassesString(
				setContent(document.createElement('div'), [
					new ToggleButton({
						clickStopPropagation:
							!options.toggleBtnClickTogglesAccordion,
						startActive: obj.component.get(options.displayProperty),
						onChange: isActive => {
							obj.component.setAndRerender(
								options.displayProperty,
								isActive
							);
						}
					}).getEl(),
					`<span>${options.accordion.title}</span>`
				]),
				'eb-flex eb-items-center eb-gap-4'
		  )
		: options.accordion.title;

	const textAccordion = new Accordion({
		title: title,
		content: textAccordionContent,
		accordionColor: options.accordion.color,
		accordionType: options.accordion.type
	});

	return textAccordion;
};
